<template>
  <transition name="fade">
    <v-dialog
      content-class="dialog-image px-0"
      persistent
      v-model="showDialog"
      max-width="400"
      rounded="xl"
    >
      <v-card style="overflow: visible" class="pb-2" rounded="xl">
        <v-card-title class="reward-title d-flex justify-center">
          <div class="d-flex justify-center">
            <v-img
              class="title-image"
              :src="require('@/assets/island/rainbow.png')"
            ></v-img>
          </div>
        </v-card-title>

        <v-card-text class="reward-body" v-if="showDialog">
          <div class="black--text text-center rounded-xl text-h6 pa-3">
            <div class="text-h4">
              {{ $t("view.PageBuildingMission.rewardReceivedMessage") }}
            </div>
            <div class="d-flex justify-center">
              <div
                v-for="reward in rewards"
                :key="reward.key"
                class="text-center pa-3"
                style="width: 85px; height: 80px"
              >
                <Item
                  :imageUrl="reward.images.icon.url"
                  :count="reward.quantity"
                  width="30"
                  :isShowCount="true"
                />
              </div>
            </div>
          </div>
          <div class="text-center pt-3 mt-5">
            <v-btn
              @click="close()"
              elevation="2"
              color="yellow"
              class="px-10 text-h6"
            >
              {{ $t("action.ok") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: [
    "rewards",
    "showDialog",
    "callbackClose",
  ],
  components: {
    Item: () =>
      import(
        /* webpackChunkName: "component-mission-reward-item" */ "@/components/shop/Item.vue"
      ),
  },
  data: () => ({}),
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  mounted() {
    let Sfx = new Audio(
      require("@/assets/sounds/claim.wav")
    );
    Sfx.play();
    Sfx.volume = this.settings.audio.sfx * this.settings.audio.master;
  },
  methods: {
    close() {
      this.callbackClose();
    },
  },
};
</script>
<style>
.dialog-image {
  overflow: visible !important;
}
.reward-title {
  position: relative !important;
  height: 60px !important;
  background-color: #ffc581;
}
.reward-body {
  position: relative !important;
}
.title-image {
  bottom: -30px !important;
  width: 150px !important;
  position: absolute !important;
}
</style>
